/* .slider {
	margin: 25px 0;
}
.min-value,
.max-value {
	float: left;
	color: #FFF !important;
	text-align: right;
	font-weight: bold;
}
.max-value {
	text-align: left;
	color: #FFF; !important;
}
.current-value {
	position: absolute;
	top: -2.5em;
	left: 50%;
	height: 1em;
	text-align: center;
	color: #81b5cc;
	font-weight: bold;
	white-space: nowrap;
}
.range {
	position: relative;
	float: left;
	padding: 0 0.9375rem;
}
input[type=range] {
	-webkit-appearance: none;
	display: block;
	height: 10px;
	padding: 0;
	border-radius: 4px;
	background: #6c6d70;
	box-sizing: content-box;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 25px;
	height: 25px;
	border: 2px solid #81b5cc;
	border-radius: 25px;
	background: #81b5cc;
} */
/* Would be nice doesn't show in Chrome
input[type=range]::-webkit-slider-thumb:before {
	content: attr(data-count);
	display: block;
	background: green;
	width: 10px;
	height: 10px;
}
*/
.range-slider > .form-control,
.range-slider > .form-control:valid {
  border: none !important;
  background-color: transparent;
}

.textAreaSuffixHeader {
  text-align: left;
  font: 11px/14px PT Sans;
  letter-spacing: 1.1px;
  color: #303030;
  text-transform: uppercase;
  opacity: 1;
  margin-top: 8px !important;
  margin-bottom: 4px !important;
}

.textAreaSuffixDescription {
  text-align: left;
  font: 14px/19px PT Sans;
  letter-spacing: 0;
  color: #707070;
  opacity: 1;
  margin-top: 16px !important;
  margin-left: 9% !important;
  white-space: pre-line;
  margin-bottom: 4px !important;
}
