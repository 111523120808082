.btnMainStory {
  color: #F6B665 !important;
  border: none !important;
  background: transparent !important;

}

.btnMainStory .dropdown-toggle {
  color: #F6B665 !important;
  border: none !important;
  background: transparent !important;
}
/* 
.btnMainStory  .dropdown-menu{
  border: none !important;
  background: transparent;
} */
/* 
.menuBtn .dropDown-menu{
}
  /* transform: translateX('30px'); */



.dropDownMenuCss {
  width: auto;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 10px #0000004d;
  border-radius: 4px;
  top: -27px !important;
  left: 30px !important;
  transform: none !important;
}

.leftSide {
  left : -270px   !important
}

.writeStoryDropDown {
  top: -11px !important;
  left: 20px !important;
}

.dropDownItemCss {
  padding: 0.2rem 1rem !important;
}

.dropDownItemCssNav {
  text-decoration: none;
  color: #212529;
}

.dropDownItemCssNav::after {
  text-decoration: none !important;
  color: #212529 !important;
}

.dropDownItemCssNav:hover {
  text-decoration: none !important;
  color: #212529 !important;
}

.dropDownItemSubmit {
  border-bottom: 1px solid #f4f4f4 !important;
}

button::after {
  content: none !important;
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.btnMainStory:hover,
.btnMainStory:focus,
.btnMainStory:active,
.btnMainStory.active {
  color: black !important;
  border: none !important;
  background: none !important ;
}
/* .accordion{
  
  margin-left: -10%;
} */
.accordion .card {
  /* background: #e6e4e4; */
  background:#F0EEF8;
}
.accordion .card-body {
  padding: 0;
  /* background: #e6e4e4; */
  background:#F0EEF8;
}
.card-body .btn-group-vertical {
  /* background: #e6e4e4; */
  background:#F0EEF8;
}
.accordion .card-header {
  padding: 0 !important;
  /* background: #e6e4e4; */
  background:#F0EEF8;
  color: #3A2C61;
  border-bottom: none !important;
}
.accordion .badge {
  padding: 2% !important;
  font-size: 12px !important;
  font-weight: normal !important;
}
.light-badge {
  background: #dfdfdf !important;
  border-radius: 45px !important;
  padding: 7px 13px !important;
  color: rgba(0, 0, 0, 0.67) !important;
  font-size: 11px !important;
  font-family: Roboto !important;
}
.storyblock-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 0 0px;
  cursor: pointer;
  width: 100%;
}

.fadeObject {
  opacity: 0.5;
}
