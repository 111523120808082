

  .pereviewModal .modal-content{
    background: #43425D !important;
  }


  

  .pereviewModal .modal-header{
    color:#fff;
    border-bottom: 1px solid rgba(255,255,255,0.2)!important;
    position: relative;
    padding: 1rem 1rem;
  }

  .pereviewModal .modal-header .btn-snacktool-tertiary{
    position: absolute;
    right: 20px;
    bottom: -20px;
    display: flex;
    align-items: center;
    z-index: 99;
    border-color: #E6A554;
    color:#E6A554 !important
  }
  .pereviewModal .modal-header .btn-snacktool-tertiary .fa-times{
    font-size: 17px;
    margin-left: 8px;
    color: #E6A554;
  } 
  .pereviewModal .modal-body{
   z-index: 9;
   padding: 1rem;
  }
  .pereviewModal .modal-footer{
    justify-content: center;
    border-top: none !important;
    position: absolute;
    height: 155px;
    background: #37364c;
    bottom: -3px;
    width: 100%;
  }


@keyframes fadeIn {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

#preview-content {
  background-repeat: no-repeat;
  /* transition: all 2s ease-in-out; */
  /*-webkit-transition: all 2s ease-in-out; /** Chrome & Safari **/
  /*-moz-transition: all 2s ease-in-out; /** Firefox **/
  /*-o-transition: all 2s ease-in-out; /** Opera **/
}

.preview-content-background {
  background-repeat: no-repeat;
  /* transition: background 2s ease-in-out; */
  /*-webkit-transition: all 2s ease-in-out; /** Chrome & Safari **/
  /*-moz-transition: all 2s ease-in-out; /** Firefox **/
  /*-o-transition: all 2s ease-in-out; /** Opera */
}

.quickdialgoue{
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0 auto;
    z-index: 99;
}
.dialgoueHeader{
  position: absolute;
    top: -15px;
    left: 10%;
    background: #854f88;
    padding: 4px 10px;
    min-width: 50%;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80%;
    overflow: hidden;
    border-radius: 6px;
    color: #fffdfd;

}
.characterDialgoue{
  padding: 25px 10px 10px;
  background: rgba(0,0,0,0.4);
  width: 90%;
  margin: 0 auto;
  border-radius: 6px;
  color: #fffdfd;

}



.button-preview-wrapper{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background:rgba(0,0,0,0.42);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  }